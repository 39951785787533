import { RegisteredEstablishmentManagementPlan } from "./RegisteredEstablishmentManagementPlan";

export class REMortality {
    ID;
    RegisteredEstablishmentConsignmentID;
    MortalityDate;
    PenName;
    CommodityTypeID;
    IsEuthanased;
    IsPostMortemConducted;
    NoPostMortemConductedReason;
    ConditionLevelThreeID;
    TagTypeID;
    TagNumber;
    PICTransferredFrom;
    IsMortalityFactorAggression;
    IsMortalityFactorFeedIssues;
    IsMortalityFactorWaterIssues;
    IsMortalityFactorShyFeeder;
    IsMortalityFactorWeatherConditions;
    IsMortalityFactorVentilationIssues;
    FurtherInformation;
    IsMortalityFactorOther;
    ArrivalDate;
    SpeciesID;
    ClassID;
    ApplicableManagementPlans;

    constructor({id, registeredEstablishmentConsignmentID, mortalityDate, penName, commodityTypeID,
        isEuthanased, isPostMortemConducted, noPostMortemConductedReason, conditionLevelThreeID, tagTypeID,
        tagNumber, picTransferredFrom, isMortalityFactorAggression, isMortalityFactorFeedIssues, isMortalityFactorWaterIssues,
        isMortalityFactorShyFeeder, isMortalityFactorWeatherConditions, isMortalityFactorVentilationIssues, furtherInformation,
        isMortalityFactorOther, arrivalDate, speciesID, classID, applicableManagementPlans
    }) 
    {
        this.ID = id;
        this.RegisteredEstablishmentConsignmentID = registeredEstablishmentConsignmentID;
        this.MortalityDate = mortalityDate;
        this.PenName = penName;
        this.CommodityTypeID = commodityTypeID;
        this.IsEuthanased = isEuthanased;
        this.IsPostMortemConducted = isPostMortemConducted;
        this.NoPostMortemConductedReason = noPostMortemConductedReason;
        this.ConditionLevelThreeID = conditionLevelThreeID;
        this.TagTypeID = tagTypeID;
        this.TagNumber = tagNumber;
        this.PICTransferredFrom = picTransferredFrom;
        this.IsMortalityFactorAggression = isMortalityFactorAggression;
        this.IsMortalityFactorFeedIssues = isMortalityFactorFeedIssues;
        this.IsMortalityFactorWaterIssues = isMortalityFactorWaterIssues;
        this.IsMortalityFactorShyFeeder = isMortalityFactorShyFeeder;
        this.IsMortalityFactorWeatherConditions = isMortalityFactorWeatherConditions;
        this.IsMortalityFactorVentilationIssues = isMortalityFactorVentilationIssues;
        this.FurtherInformation = furtherInformation;
        this.IsMortalityFactorOther = isMortalityFactorOther;
        this.ArrivalDate = arrivalDate;
        this.SpeciesID = speciesID;
        this.ClassID = classID;
        this.ApplicableManagementPlans = applicableManagementPlans.map(amp => new RegisteredEstablishmentManagementPlan(amp));
    }
}