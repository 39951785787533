import { useNavigate } from "react-router-dom";
import ConsignmentProgression from "../../components/ConsignmentCreation/ConsignmentProgression";
import { Container, Row, Col, Form, Button, Stack, Badge, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { Tooltip } from "react-tooltip";
import { AAV } from "../../classes/AAV";
import { getAAVs, getAccreditedStockpersons } from "../../api/apiAccess";
import { addToOrUpdateSessionConsignmentBeingCreated, getSessionConsignmentBeingCreated } from "../../helpers/SessionHelper";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";

const Consignment_Personnel = () => {
    const navigate = useNavigate();
    const [consignment, setConsignment] = useState(null);
    const [aavMasterData, setAAVMasterData] = useState([]);
    const [accreditedStockPersonMasterData, setAccreditedStockPersonMasterData] = useState([]);
    const [otherAccStockpersons, setOtherAccStockpersons] = useState(false);
    const [otherAccStockpersonsInvalid, setOtherAccStockpersonsInvalid] = useState(false);
    const [otherAAVList, setOtherAAVList] = useState([]);
    const [accreditedStockpersonsList, setAccreditedStockpersonsList] = useState([]);
    const [competentStockpersons, setCompetentStockpersons] = useState([]);
    const [dailyReportingRequired, setDailyReportingRequired] = useState(true);
    const [independentObserverOnboard, setIndependentObserverOnboard] = useState(false);
    const [triggerGoNext, setTriggerGoNext] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        let fetchAAVs = async () => {
            let results = await getAAVs();
            if (results?.status === 200) {
                setAAVMasterData(results.data);
            }
        }

        let fetchAccreditedStockPersons = async () => {
            let results = await getAccreditedStockpersons();
            if (results?.status === 200) {
                setAccreditedStockPersonMasterData(results.data);
            }
        }

        let fetchAll = async () => {
            await fetchAAVs();
            await fetchAccreditedStockPersons();
        }

        fetchAll();

        let consignmentBeingCreated = getSessionConsignmentBeingCreated();
        if (consignmentBeingCreated) {
            setDailyReportingRequired(consignmentBeingCreated.requiresDailyReporting ? consignmentBeingCreated.requiresDailyReporting : true);
            setOtherAAVList(consignmentBeingCreated.otherAAVs ?? []);
            setAccreditedStockpersonsList(consignmentBeingCreated.otherAccreditedStockpersons ?? []);

            // default the will there be any accredited Stockpersons on board question to true when creating a new consignment
            if (consignmentBeingCreated?.isEditMode === true) {
                setOtherAccStockpersons(consignmentBeingCreated?.otherAccreditedStockpersons?.length > 0);
            }
            else {
                setOtherAccStockpersons(true);
            }

            let lCompetentStockpersons = [];
            if (consignmentBeingCreated?.competentStockpersons) {
                consignmentBeingCreated?.competentStockpersons.forEach(csp => {
                    csp.isValidFirstName = true;
                    csp.isValidLastName = true;
                    lCompetentStockpersons.push(csp);
                })
            }
            if (lCompetentStockpersons.length < 5) {
                let i = 0;
                while (lCompetentStockpersons.length < 5) {
                    lCompetentStockpersons.push({ key: `csp_${lCompetentStockpersons.length + i}`, firstName: '', lastName: '', isValidFirstName: true, isValidLastName: true });
                    i++;
                }
            }

            setCompetentStockpersons(lCompetentStockpersons);
            setIndependentObserverOnboard(consignmentBeingCreated?.independentObserverOnboard ?? false)
        }
    }, []);

    useEffect(() => {
        if (triggerGoNext > 0) {
            updateConsignmentBeingCreated();
            navigate('/createconsignmentlivestock');
        }
    }, [triggerGoNext]);

    const goBack = () => {
        navigate('/createconsignment');
    }

    const goToNext = (e) => {
        let aavListIsValid = validateOtherAAVList();
        let competentStockPersonListIsValid = validateCompetentStockpersons();
        let accreditedStockPersonValid = validateAccreditedStockPersons();
        if (checkNoAAVsAndAccreditedStockpersons() && aavListIsValid && competentStockPersonListIsValid && accreditedStockPersonValid) {
            stripEmptyCompetentStockpersonRows();
            setTriggerGoNext(triggerGoNext + 1);
        }
    }

    const stripEmptyCompetentStockpersonRows = () => {
        setCompetentStockpersons(competentStockpersons.filter(csp => !StringIsNullUndefinedOrEmpty(csp.firstName)));
    }

    const checkNoAAVsAndAccreditedStockpersons = () => {
        if (!otherAAVList.length && !otherAccStockpersons) {
            setError("Please select at least one AAV or Accredited Stockperson.");
            return false;
        } else {
            setError(null);
        }
        return true;
    }

    const addAAV = () => {
        let tempAAVList = [...otherAAVList];
        let newAAV = new AAV();

        tempAAVList.push(newAAV);
        setOtherAAVList(tempAAVList);
    }

    const removeAAV = (aav) => {
        let tempAAVList = [...otherAAVList];
        tempAAVList = tempAAVList.filter(aavs => aavs.key !== aav.key);
        setOtherAAVList(tempAAVList);
    }

    const updateAdditionalAAVFirstName = (aav, firstName) => {
        let tempAAVList = [...otherAAVList];
        tempAAVList.find(aavInList => aavInList.key === aav.key).firstName = firstName;
        setOtherAAVList(tempAAVList);
    }

    const updateAdditionalAAVLastName = (aav, lastName) => {
        let tempAAVList = [...otherAAVList];
        tempAAVList.find(aavInList => aavInList.key === aav.key).lastName = lastName;
        setOtherAAVList(tempAAVList);
    }

    const updateAdditionalAAVRegNumber = (aav, accreditationNumber) => {
        let tempAAVList = [...otherAAVList];
        tempAAVList.find(aavInList => aavInList.key === aav.key).accreditationNumber = accreditationNumber;
        setOtherAAVList(tempAAVList);
    }

    const addAccreditedStockperson = (sp) => {
        let tempAccStockpersonList = [...accreditedStockpersonsList];
        if (tempAccStockpersonList.filter(existingSP => existingSP === sp).length > 0) return;
        tempAccStockpersonList.push(sp);
        setOtherAccStockpersonsInvalid(false);
        setAccreditedStockpersonsList(tempAccStockpersonList);
    }

    const removeAdditionalStockperson = (sp) => {
        let tempAccStockpersonList = [...accreditedStockpersonsList];
        tempAccStockpersonList = tempAccStockpersonList.filter(existingSP => existingSP !== sp);
        setAccreditedStockpersonsList(tempAccStockpersonList);
    }

    const addCompetentStockpersonRow = () => {
        let tempCompetentStockpersons = [...competentStockpersons];
        let lastCompetentStockperson = tempCompetentStockpersons.length > 0 ? tempCompetentStockpersons[tempCompetentStockpersons.length - 1] : undefined;
        let nextKeyIndex = lastCompetentStockperson ? lastCompetentStockperson.key.split("_")[1] : -1;
        tempCompetentStockpersons.push({ firstName: '', lastName: '', isValidFirstName: true, isValidLastName: true, key: `csp_${nextKeyIndex + 1}` });
        setCompetentStockpersons(tempCompetentStockpersons);
    }

    const updateCompetentStockpersonFirstName = (csp, firstName) => {
        let competentStockpersonList = [...competentStockpersons];
        let lItemToUpdate = competentStockpersonList.find(cspInList => cspInList.key === csp.key);
        lItemToUpdate.firstName = firstName;
        lItemToUpdate.isValidFirstName = true;
        setCompetentStockpersons(competentStockpersonList);
    }

    const updateCompetentStockpersonLastName = (csp, lastName) => {
        let competentStockpersonList = [...competentStockpersons];
        let lItemToUpdate = competentStockpersonList.find(cspInList => cspInList.key === csp.key);
        lItemToUpdate.lastName = lastName;
        lItemToUpdate.isValidLastName = true;
        setCompetentStockpersons(competentStockpersonList);
    }

    const validateAccreditedStockPersons = () => {
        let lValid = true;

        // If yes is selected, must have at least one name input
        if (otherAccStockpersons && accreditedStockpersonsList.length === 0) {
            lValid = false;
        }

        setOtherAccStockpersonsInvalid(!lValid);

        return lValid;
    }

    const validateOtherAAVList = () => {
        let tempAAVList = [...otherAAVList];
        let isAnyInvalid = false;
        tempAAVList.forEach(aav => {
            if (isNaN(aav.accreditationNumber)) {
                aav.isValid = false;
                isAnyInvalid = true;
                return;
            }

            if (aav.lastName.length === 0) {
                aav.isValid = false;
                isAnyInvalid = true;
                return;
            }

            let existingAAV = aavMasterData.find(existing => existing.lastName.toLowerCase() === aav.lastName.toLowerCase() && Number(existing.accreditationNumber) === Number(aav.accreditationNumber));
            if (existingAAV) {
                aav.id = existingAAV.id;
                aav.lastName = existingAAV.lastName;
                aav.firstName = existingAAV.firstName;
            } else {
                aav.isValid = false;
                isAnyInvalid = true;
                return;
            }
        });
        setOtherAAVList(tempAAVList);
        return !isAnyInvalid;
    }

    const validateCompetentStockpersons = () => {
        let tempCompetentStockpersons = [...competentStockpersons];
        tempCompetentStockpersons = [...new Set(competentStockpersons)]


        let lAllValid = true;

        if (tempCompetentStockpersons) {
            tempCompetentStockpersons.forEach(csp => {
                // Only validate rows that have either a first name or last name.  Empty rows will be ignored.
                if (!StringIsNullUndefinedOrEmpty(csp.firstName) || !StringIsNullUndefinedOrEmpty(csp.lastName)) {
                    // Only the first name is required.  The last name can be blank.
                    if (StringIsNullUndefinedOrEmpty(csp.firstName)) {
                        csp.isValidFirstName = false;
                        lAllValid = false;
                    }
                    else {
                        csp.isValidFirstName = true;
                        csp.isValidLastName = true;
                    }
                }
            })
        }

        // We may need more validation here
        setCompetentStockpersons(tempCompetentStockpersons);
        return lAllValid;
    }

    const updateConsignmentBeingCreated = () => {
        addToOrUpdateSessionConsignmentBeingCreated({
            requiresDailyReporting: dailyReportingRequired,
            otherAAVs: otherAAVList.map(aav => aav),
            otherAccreditedStockpersons: accreditedStockpersonsList.map(asp => asp),
            competentStockpersons: competentStockpersons.map(csp => csp),
            independentObserverOnboard: independentObserverOnboard
        })
    }

    const progressStages = [
        { title: "Voyage Details", completed: true },
        { title: "Personnel & Reporting", completed: false },
        { title: "Livestock", completed: false },
        { title: "Approved Management Plans", completed: false }
    ];

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>Create new consignment</div>
                                <ConsignmentProgression stages={progressStages} currentStage={progressStages[1]} key="consignment_progress" />
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container className="py-4 px-5 form-container">
                        <Row className="mb-2">
                            <Col xs={12}>
                                <div className="heading-text darkblue">Personnel and reporting</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} className="darkblue field-label mb-1">
                                Reporting Requirements
                            </Col>
                            <Col xs={12}>
                                <Form>
                                    <Form.Check
                                        type={`checkbox`}
                                        label={`This voyage requires daily reporting`}
                                        id={`daily-reporting-required`}
                                        checked={dailyReportingRequired === true}
                                        onChange={() => setDailyReportingRequired(!dailyReportingRequired)}
                                        className="field-label secondary-grey"
                                    />
                                </Form>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col xs={12} className="field-label">
                                Personnel
                            </Col>
                        </Row>
                        <Row className="mb-2 mt-3">
                            <Col xs={12} className="darkblue field-label mb-1">
                                Will there be any AAVs on board?
                                <Form>
                                    <Form.Check
                                        type={`radio`}
                                        label={`No`}
                                        id={`other-aavs-no`}
                                        checked={otherAAVList?.length === 0}
                                        onChange={() => { setOtherAAVList([]) }}
                                    />
                                    <Form.Check
                                        type={`radio`}
                                        label={`Yes`}
                                        id={`other-aavs-yes`}
                                        checked={otherAAVList?.length > 0}
                                        onChange={() => { setOtherAAVList([new AAV('empty_0')]) }}
                                    />
                                </Form>
                            </Col>
                        </Row>
                        {otherAAVList?.length > 0 ? (
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={3} className="field-label align-bottom">
                                            AAV First name
                                        </Col>
                                        <Col xs={3} className="field-label align-bottom">
                                            AAV Last name
                                        </Col>
                                        <Col xs={3} className="field-label align-bottom">
                                            AAV Registration number
                                        </Col>
                                    </Row>
                                    {otherAAVList && otherAAVList.length > 0 ? (
                                        <>
                                            {otherAAVList.map((aav, index) => {
                                                return (
                                                    <Row key={index} className="mb-2">
                                                        <Col xs={3}>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    onChange={(e) => {
                                                                        aav.isValid = true;
                                                                        updateAdditionalAAVFirstName(aav, e.currentTarget.value);
                                                                    }}
                                                                    value={aav.firstName}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    isInvalid={!aav.isValid}
                                                                    id={`aav-last-name-${aav.key}`}
                                                                    onChange={(e) => {
                                                                        aav.isValid = true;
                                                                        updateAdditionalAAVLastName(aav, e.currentTarget.value);
                                                                    }}
                                                                    value={aav.lastName}
                                                                />
                                                                {!aav.isValid ?
                                                                    (
                                                                        <Tooltip
                                                                            anchorSelect={`#aav-last-name-${aav.key}`}
                                                                            variant="error"
                                                                            content="Ensure the last name is spelled correctly."
                                                                            style={{ zIndex: 10, borderRadius: '8px', opacity: 1 }}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    isInvalid={!aav.isValid}
                                                                    id={`aav-reg-num-${aav.key}`}
                                                                    onChange={(e) => {
                                                                        aav.isValid = true;
                                                                        updateAdditionalAAVRegNumber(aav, e.currentTarget.value);
                                                                    }}
                                                                    value={aav.accreditationNumber}
                                                                />
                                                                {!aav.isValid ?
                                                                    (
                                                                        <Tooltip
                                                                            anchorSelect={`#aav-reg-num-${aav.key}`}
                                                                            variant="error"
                                                                            content="Ensure the registration number does not contain letters and matches the provided AAV last names."
                                                                            style={{ zIndex: 10, borderRadius: '8px', maxWidth: "260px", opacity: 1 }}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => {
                                                                    removeAAV(aav);
                                                                }}
                                                                className="px-2 py-1"
                                                                style={{ verticalAlign: "0.2em" }}
                                                            >
                                                                <FontAwesomeIcon icon={faCircleXmark} />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                            <div className="cc-add-port-button px-2 py-1 mb-4" onClick={addAAV}><FontAwesomeIcon className="primary-green" icon={faCirclePlus} /> Add another AAV</div>
                                        </>
                                    ) : null}

                                </Col>
                            </Row>
                        ) : null
                        }
                        <Row className="mb-2">
                            <Col xs={12} className="darkblue field-label mb-1">
                                Will there be any Accredited Stockpersons on board?
                                <Form>
                                    <Form.Check
                                        type={`radio`}
                                        label={`No`}
                                        id={`other-acc-stockpersons-no`}
                                        checked={otherAccStockpersons === false}
                                        onChange={() => {
                                            setOtherAccStockpersons(false)
                                            setAccreditedStockpersonsList([]);
                                        }
                                        }
                                    />
                                    <Form.Check
                                        type={`radio`}
                                        label={`Yes`}
                                        id={`other-acc-stockpersons-yes`}
                                        checked={otherAccStockpersons === true}
                                        onChange={() => {
                                            setOtherAccStockpersons(true);
                                        }
                                        }
                                    />
                                </Form>
                            </Col>
                        </Row>
                        {otherAccStockpersons && accreditedStockPersonMasterData.length > 0 ? (
                            <Row className={accreditedStockpersonsList?.length === 0 ? "mb-4" : "mb-2"}>
                                <Col id="acc-stockperson-col" xs={6}>
                                    <Typeahead
                                        isInvalid={otherAccStockpersonsInvalid}
                                        id="acc-stockperson-typeahead"
                                        onChange={(e) => addAccreditedStockperson(e[0])}
                                        labelKey={(option) => `${option.firstName} ${option.lastName}`}
                                        options={accreditedStockPersonMasterData.filter(item => !accreditedStockpersonsList.includes(item))}
                                        selected={[]}
                                    />
                                    {otherAccStockpersonsInvalid ?
                                        (
                                            <Tooltip
                                                anchorSelect="#acc-stockperson-col"
                                                variant="error"
                                                content="Please add at least one accredited stockperson."
                                            />
                                        ) : null
                                    }
                                </Col>

                            </Row>
                        ) : null
                        }
                        {otherAccStockpersons && accreditedStockpersonsList.length > 0 ? (
                            <Row className="mb-4">
                                <Col xs={6}>
                                    <span className="field-label">On this voyage:</span> {accreditedStockpersonsList.map(sp => {
                                        return (
                                            <Badge key={`sp_${sp.firstName}_${sp.lastName}`} className="mx-2 cc-stockperson-badge" bg="secondary">{`${sp.firstName} ${sp.lastName}`}<span onClick={(e) => removeAdditionalStockperson(sp)} className="p-0 ms-1" style={{ color: 'white' }}><FontAwesomeIcon icon={faCircleXmark} className="p-0 m-0" /></span></Badge>
                                        )
                                    })}
                                </Col>
                            </Row>
                        ) : null}
                        <Row className="mb-2">
                            <Col xs={12} className="darkblue field-label mb-1">
                                Please provide the legal names of all Competent Stockpersons onboard this voyage
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col xs={3} className="darkblue field-label">
                                First name
                            </Col>
                            <Col xs={3} className="darkblue field-label">
                                Last name
                            </Col>
                        </Row>
                        {competentStockpersons?.map((sp, index) => {
                            return (
                                <Row key={index} className="mb-2">
                                    <Col xs={3}>
                                        <Form onSubmit={e => { e.preventDefault(); }}>
                                            <Form.Group>
                                                <Form.Control
                                                    id={`csp-first-name-${index}`}
                                                    onChange={(e) => {
                                                        updateCompetentStockpersonFirstName(sp, e.target.value)
                                                    }}
                                                    value={sp.firstName}
                                                    isInvalid={!sp.isValidFirstName}
                                                />
                                                {!sp.isValidFirstName ?
                                                    (
                                                        <Tooltip
                                                            anchorSelect={`#csp-first-name-${index}`}
                                                            variant="error"
                                                            content="A first name is required."
                                                            style={{ zIndex: 10, borderRadius: '8px', maxWidth: "260px", opacity: 1 }}
                                                        />
                                                    ) : null
                                                }
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col xs={3}>
                                        <Form onSubmit={e => { e.preventDefault(); }}>
                                            <Form.Group>
                                                <Form.Control
                                                    id={`csp-last-name-${index}`}
                                                    onChange={(e) => {
                                                        updateCompetentStockpersonLastName(sp, e.target.value)
                                                    }
                                                    }
                                                    value={sp.lastName}
                                                    isInvalid={!sp.isValidLastName}
                                                />
                                                {!sp.isValidLastName ?
                                                    (
                                                        <Tooltip
                                                            anchorSelect={`#csp-last-name-${index}`}
                                                            variant="error"
                                                            content="A last name is required."
                                                            style={{ zIndex: 10, borderRadius: '8px', maxWidth: "260px", opacity: 1 }}
                                                        />
                                                    ) : null
                                                }
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Row>
                            <Col xs={12}>
                                <div className="cc-add-port-button px-2 py-1" onClick={addCompetentStockpersonRow}><FontAwesomeIcon className="primary-green" icon={faCirclePlus} /> Add another competent stockperson</div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="voluntary-data-bg p-2">
                                <Form>
                                    <Form.Check
                                        type={`checkbox`}
                                        label={`There is an Independent Observer/IO on this voyage.`}
                                        id={`independent-observer-on-voyage`}
                                        checked={independentObserverOnboard === true}
                                        onChange={() => setIndependentObserverOnboard(!independentObserverOnboard)}
                                        className="field-label secondary-grey"
                                    />
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Container className="button-container">
                    <Row className="w-100 pt-4" style={{ position: "relative", top: "-300px" }}>
                        {error && error?.length > 0 && (<Alert variant="danger">{error}</Alert>)}
                        <Stack direction="horizontal" gap={2}>
                            <Button variant="primary" onClick={goToNext} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>Next</Button>
                            <Button className="me-2 me-auto" variant="secondary" onClick={goBack} size="lg" style={{ minWidth: "150px" }}>Previous</Button>
                        </Stack>
                    </Row>
                </Container>
            </Container>
        </LoggedInContainer>
    )
};

export default Consignment_Personnel;