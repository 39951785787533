export class PlannedMortalitySubmission {
    MortalityDate;
    PenName;
    CommodityTypeID;
    IsEuthanased;
    IsPostMortemConducted;
    NoPostMortemConductedReason;
    ConditionLevelThreeID;
    TagTypeID;
    TagNumber;
    PICTransferredFrom;
    IsMortalityFactorAggression;
    IsMortalityFactorFeedIssues;
    IsMortalityFactorWaterIssues;
    IsMortalityFactorShyFeeder;
    IsMortalityFactorWeatherConditions;
    IsMortalityFactorVentilationIssues;
    IsMortalityFactorOther;
    ArrivalDate;
    SpeciesID;
    ClassID;  
    ApplicableManagementPlanIDs;
    FurtherInformation;

    // we want to save the mortality date and arrival date as whatever the user selects with no conversion
    // to do this format as 'yyy-MM-dd' so that the C# side recognises it as a date without converting to to a specific timezone 
    constructor(mortalityDate, penName, commodityTypeID, isEuthanased, isPostMortemConducted, noPostMortemConductedReason, levelThreeConditionID, tagTypeID, tagNumber, picTransferredFrom, mortalityFactors, arrivalDate, speciesID, classID, applicableManagementPlanIDs, FurtherInformation) {
        this.MortalityDate = mortalityDate;
        this.PenName = penName;
        this.CommodityTypeID = commodityTypeID;
        this.IsEuthanased = isEuthanased;
        this.IsPostMortemConducted = isPostMortemConducted;
        this.NoPostMortemConductedReason = noPostMortemConductedReason;
        this.ConditionLevelThreeID = levelThreeConditionID;
        this.TagTypeID = tagTypeID;
        this.TagNumber = tagNumber;
        this.PicTransferredFrom = picTransferredFrom;
        this.IsMortalityFactorAggression = mortalityFactors && mortalityFactors.find(mf => mf.id === 1 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorFeedIssues = mortalityFactors && mortalityFactors.find(mf => mf.id === 2 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorWaterIssues = mortalityFactors && mortalityFactors.find(mf => mf.id === 3 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorShyFeeder = mortalityFactors && mortalityFactors.find(mf => mf.id === 6 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorWeatherConditions = mortalityFactors && mortalityFactors.find(mf => mf.id === 4 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorVentilationIssues = mortalityFactors && mortalityFactors.find(mf => mf.id === 5 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorOther = mortalityFactors && mortalityFactors.find(mf => mf.id === 7 && mf.isChecked === true) !== undefined;
        this.ArrivalDate = arrivalDate;
        this.SpeciesID = speciesID;
        this.ClassID = classID;
        this.ApplicableManagementPlanIDs = applicableManagementPlanIDs  && Array.isArray(applicableManagementPlanIDs) ? [...applicableManagementPlanIDs] : [];
        this.FurtherInformation = FurtherInformation;
    }
}