import React, { useEffect, useState } from "react";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { RegisteredEstablishmentConsignmentMortality } from "../../classes/RE/RegisteredEstablishmentConsignmentMortality";
import { formatInTimeZone } from "../../utils/common";
import { getCommodityTypes, getSpecies, getSpeciesClasses, getDetailedMortalityConditions, getREMortalityById, getTagTypes } from "../../api/apiAccess";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";

const ViewREMortalityReport = () => {
    const navigate = useNavigate();
    const {mortalityID} = useParams();
    const [mortality, setMortality] = useState("");
    const [commodityTypes, setCommodityTypes] = useState([]);
    const [earTagTypes, setEarTagTypes] = useState([]);
    const [mortalityConditions, setMortalityConditions] = useState([]);
    const [mortalityCondition, setMortalityCondition] = useState("");
    const [specieClass, setSpecieClass] = useState({specie:null,class:null});

    useEffect(() => {

        const fetchMortalityConditions = async () => {
            let results = await getDetailedMortalityConditions();
            if (results.status === 200) {
                setMortalityConditions(results.data);
            }
        }

        const fetchCommodityTypes = async () => {
            let results = await getCommodityTypes();
            if (results.status === 200) {
                setCommodityTypes(results.data);
            }
        }

        const fetchEarTagTypes = async() => {
            let results = await getTagTypes();
            if (results.status === 200) {
                setEarTagTypes(results.data);
            }
        }

        const fetchMortality = async () => {
            let results = await getREMortalityById(mortalityID);
            if (results.status === 200) {
                let mortalityResult = new RegisteredEstablishmentConsignmentMortality(results.data);
                setMortality(mortalityResult);
                let sc_results = await getSpeciesClasses();
                let s_results = await getSpecies();
                console.log(mortalityResult,s_results.data.find(s=>s.id === mortalityResult.SpeciesID),sc_results.data.find(sc=>sc.id === mortalityResult.ClassID))
                setSpecieClass({specie:s_results.data.find(s=>s.id === mortalityResult.SpeciesID),class:sc_results.data.find(sc=>sc.id === mortalityResult.ClassID)})
            }
        }

        fetchMortalityConditions();
        fetchCommodityTypes();
        fetchEarTagTypes();
        fetchMortality();
    }, [mortalityID])

    useEffect(() => {
        if (!mortalityConditions || mortalityConditions.length === 0) return;
        if (!mortality) return;
        let specificMortalityConditionL3 = mortalityConditions.find(hc => Number(hc.levelThree.id) === Number(mortality.ConditionLevelThreeID));
        let specificMortalityConditionL2 = mortalityConditions.find(hc => Number(hc.levelTwo.id) === Number(specificMortalityConditionL3.levelThree.parentConditionID));
        let specificMortalityConditionL1 = mortalityConditions.find(hc => Number(hc.levelOne.id) === Number(specificMortalityConditionL2.levelTwo.parentConditionID));
        setMortalityCondition({
            levelOne: specificMortalityConditionL1.levelOne.conditionName,
            levelTwo: specificMortalityConditionL2.levelTwo.conditionName,
            levelThree: specificMortalityConditionL3.levelThree.conditionName
        })

    }, [mortalityConditions, mortality])

    const getMortalityFactorsDesciption = (mortality) => {
        if (mortality === "") return;
        let factors = [];
        if (mortality.IsMortalityFactorAggression) factors.push("Aggression");
        if (mortality.IsMortalityFactorFeedIssues) factors.push("Feed issues");
        if (mortality.IsMortalityFactorShyFeeder) factors.push("Shy feeder");
        if (mortality.IsMortalityFactorVentilationIssues) factors.push("Ventilation issues");
        if (mortality.IsMortalityFactorWaterIssues) factors.push("Water issues");
        if (mortality.IsMortalityFactorWeatherConditions) factors.push("Weather conditions");
        if (mortality.IsMortalityFactorOther) factors.push("Other");
        if (factors.length === 0) return "None"
        return factors.join(', ');
    }

    const goBack = () => {
        navigate(`/re/consignmentdashboard/${mortality?.RegisteredEstablishmentConsignmentID}`)
    }

    return (
        <LoggedInContainer>
              <Container fluid className="p-0" style={{backgroundColor: "#F5F5F5"}}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-5" style={{color: "#FFF", lineHeight: "53px", fontSize: "35px"}}>View mortality</div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container className="py-4 px-5 form-container-mid" style={{top: "-350px"}}>
                        <Row className="mt-2">
                            <Col xs={12}>
                                <div className="heading-text darkblue">Animal Details</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{height: "2px"}}></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="field-label">
                                Mortality Date
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Stack direction="vertical">
                                    <div>{mortality?.MortalityDate ? `${formatInTimeZone(mortality?.MortalityDate, "dd/MM/yyyy", Intl.DateTimeFormat().resolvedOptions().timeZone)}` : ""}</div>
                                </Stack>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={3} className="field-label">
                                Pen Name
                            </Col>
                            <Col xs={3} className="field-label">
                                Species/Class
                            </Col>
                            <Col xs={4} className="field-label">
                                Commodity Type
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                {mortality?.PenName}
                            </Col>
                            <Col xs={3}> 
                                {specieClass?.specie?.speciesName}/{specieClass?.class?.className}
                            </Col>
                            <Col xs={4}>
                                {commodityTypes?.find(ct => Number(ct.id) === Number(mortality?.CommodityTypeID))?.commodityName}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={3} className="field-label">
                                Tag Number
                            </Col>
                            <Col xs={3} className="field-label">
                                Ear Tag Type
                            </Col>
                            <Col xs={3} className="field-label">
                                PIC Transferred From
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                {mortality?.TagNumber}
                            </Col>
                            <Col xs={3}>
                                {earTagTypes.find(tt => tt.id === mortality?.TagTypeID)?.tagType}
                            </Col>
                            <Col xs={3}>
                                {mortality?.PICTransferredFrom}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={4} className="field-label">
                                Arrival at RE Date
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                {`${formatInTimeZone(mortality?.ArrivalDate, "dd/MM/yyyy", Intl.DateTimeFormat().resolvedOptions().timeZone)}`}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12} className="field-label">
                                Applicable management plans
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {
                                    mortality?.ApplicableManagementPlans?.filter(mp => mp.id > 0).length > 0 ? (
                                        mortality?.ApplicableManagementPlans?.filter(mp => mp.id > 0).map((amp, i) => {
                                            return (
                                                <div key={`applicable_mp_${i}`}>{amp.managementPlanName}</div>
                                            )
                                        })
                                    ) : (
                                        <>There are no applicable management plans for this mortality.</>
                                    )
                                }
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col xs={12}>
                                <div className="heading-text darkblue">Mortality Details</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{height: "2px"}}></div>
                            </Col>
                        </Row>
                       
                        <Row className="mt-3">
                            <Col xs={12} className="field-label">
                                Status
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {mortality?.IsEuthanased ? "Euthanased" : "Found dead"}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12} className="field-label">
                                Post-mortem Conducted?
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {mortality?.IsPostMortemConducted ? "Yes" : "No"}
                            </Col>
                        </Row>
                        {
                            mortality?.IsPostMortemConducted !== true ? (
                                <>
                                    <Row>
                                        <Col xs={12} className="field-label">
                                            No Post-mortem Conducted Reason
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            {mortality?.NoPostMortemConductedReason}
                                        </Col>
                                    </Row>
                                </>
                            ) : null
                        }

                        <Row className="mt-3">
                            <Col xs={12} className="field-label">
                                Diagnosis / Presumptive diagnosis
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div>{mortalityCondition?.levelOne}</div>
                                <div className="ms-2">{mortalityCondition?.levelTwo}</div>
                                <div className="ms-4">{mortalityCondition?.levelThree}</div>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs={12} className="field-label">
                                Mortality Factors
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {getMortalityFactorsDesciption(mortality)}
                            </Col>
                        </Row>
                        {
                            !StringIsNullUndefinedOrEmpty(mortality?.FurtherInformation) ? (
                                <>
                                    <Row>
                                        <Col xs={12} className="field-label">
                                            Further Information
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            {mortality?.FurtherInformation}
                                        </Col>
                                    </Row>
                                </>
                            ) : null
                        }
                    </Container>
                </Row>
            </Container>
            <Container className="button-container-mid">
                    <Row className="w-100 pt-4" style={{position: "relative", top:"-350px"}}>
                        <Stack direction="vertical">
                            <Stack direction="horizontal" gap={2}>
                                <Button className="ms-2 me-auto" variant="secondary" onClick={goBack} size="lg" style={{ minWidth: "150px" }}>Back</Button>
                            </Stack>
                        </Stack>
                    </Row>
                </Container>
        </LoggedInContainer>
    )
}

export default ViewREMortalityReport;