// we want to save the last animal date as whatever the user selects with no conversion
// to do this format as 'yyy-MM-dd' so that the C# side recognises it as a date without converting to to a specific timezone
export class PlannedRegisteredConsignmentSubmission {
    ConsignmentID;
    DateSubmitted;
    LastAnimalDepartureDate;
    constructor(consignmentID, dateSubmitted, lastAnimalDepartureDate) {
        this.ConsignmentID = consignmentID;
        this.DateSubmitted = dateSubmitted;
        this.LastAnimalDepartureDate = lastAnimalDepartureDate;
    }
}