import "../css/dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Stack, Alert, Spinner, Form } from "react-bootstrap";
import LoggedInContainer from "../layout/LoggedInContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import {
  getDeparturePorts,
  getConsignmentDeparturePortsForConsignment,
  getDestinationPorts,
  getConsignmentDestinationPortsForConsignment,
  getMortalitiesForConsignmentID,
  getLivestockLoadedConsignment,
  getLivestockDischargedForConsignment,
  getSpeciesForConsignmentID,
  getEditPageReasons,
  getConsignment,
  patchDischargeReport,
  setDischargeReportAsReviewed,
  getConsignmentDaysForConsignmentID
} from "../api/apiAccess";
import { differenceInHours } from "date-fns";
import { MediaQueryContext, UserContext } from "..";
import DateTimePicker from "../components/DateTimePicker";
import { NumericValidationRules, parseNumericInput } from "../utils/InputValidator";
import EditLogModal from "../components/EditLogModal";
import { StringIsNullUndefinedOrEmpty } from "../utils/StringUtils";
import { differenceInMinutes } from "date-fns";
import { DischargeReportLivestockDischarged, DischargeReportSave } from "../classes/DischargeReportSave";
import ReadWriteButton from "../components/ReadWriteElements/ReadWriteButton";
import { getPortLocalDatetime, getPortDateTimeUTC } from "../utils/common";

const Discharge_Report = () => {
  const headerContainerRef = useRef();
  const location = useLocation();
  const mediaQueryCtx = useContext(MediaQueryContext);
  const { consignmentDestinationPortID, consignmentID } = useParams();
  const userCtx = useContext(UserContext);

  const navigate = useNavigate();

  const [hasData, setHasData] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [loadError, setHasLoadError] = useState(false);
  const [saveError, setHasSaveError] = useState(false);
  const [saveErrorMessage, setHasSaveErrorMessage] = useState(false);
  const [saveSuccess, setHasSaveSuccess] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);

  const [editModalShow, setEditModalShow] = useState(false);
  const [editPageReasons, setEditPageReasons] = useState([]);

  const [lncNumber, setLNCNumber] = useState("");

  const [departurePorts, setDeparturePorts] = useState([]);
  const [destinationPorts, setDestinationPorts] = useState([]);

  const [vesselArrived, setVesselArrived] = useState(null);
  const [dischargeCommenced, setDischargeCommenced] = useState(null);
  const [dischargeCompleted, setDischargeCompleted] = useState(null);
  const [dischargingForHours, setDischargingForHours] = useState("");

  const [consignmentSpecies, setConsignmentSpecies] = useState([]);
  const [consignmentMortalities, setConsignmentMortalities] = useState([]);
  const [livestockLoaded, setLivestockLoaded] = useState([]);
  const [livestockDischarged, setLivestockDischarged] = useState([]);

  const [livestockDischargedDisplay, setLivestockDischargedDisplay] = useState([]);
  const [livestockDischargedSummary, setLivestockDischargedSummary] = useState([]);

  const [vesselArrivedDateValid, setVesselArrivedDateValid] = useState(true);

  const [dischargingCommencedDateValid, setdischargingCommencedDateValid] = useState(true);
  const [dischargeCommencedDateErrorMessage, setDischargeCommencedDateErrorMessage] = useState("");

  const [dischargingCompletedDateValid, setdischargingCompletedDateValid] = useState(true);
  const [dischargeCompletedDateErrorMessage, setDischargeCompletedDateErrorMessage] = useState("");

  const goBack = () => {
    navigate(-1, {state: {isHistorical: location?.state?.isHistorical}});
  };

  const populatePage = async () => {
    const updateIsReviewedStatus = async (reportID, isReviewed) => {
      if (userCtx.user?.userTypeID === 1 && isReviewed === false) {
        await setDischargeReportAsReviewed(reportID);
      }
    }

    let lConsignmentID = Number(consignmentID);

    let lPromises = [
      getDeparturePorts(),
      getDestinationPorts(),
      getConsignmentDeparturePortsForConsignment(lConsignmentID),
      getConsignmentDestinationPortsForConsignment(lConsignmentID),
      getMortalitiesForConsignmentID(lConsignmentID),
      getLivestockLoadedConsignment(lConsignmentID),
      getLivestockDischargedForConsignment(lConsignmentID),
      getSpeciesForConsignmentID(lConsignmentID),
      getEditPageReasons(),
      getConsignment(lConsignmentID),
      getConsignmentDaysForConsignmentID(lConsignmentID)
    ];

    Promise.all(lPromises)
      .then((response) => {
        if (response && response.every((resp) => resp.status === 200)) {
          let lDeparturePorts = response[0].data;
          let lDestinationPorts = response[1].data;
          let lDeparturePortsForConsignment = response[2].data;
          let lDestinationPortsForConsignment = response[3].data;
          let lMortalities = response[4].data;
          let lLivestockLoaded = response[5].data;
          let lLivestockDischarged = response[6].data;
          let lSpecies = response[7].data;
          let editPageReasons = response[8].data;
          let lConsignment = response[9].data;
          let lConsignmentDays = response[10].data;

          setConsignmentMortalities(lMortalities);
          setLivestockLoaded(lLivestockLoaded);
          setLivestockDischarged(lLivestockDischarged);
          setConsignmentSpecies(lSpecies);
          setLNCNumber(lConsignment.lncNumber);

          let reasons = editPageReasons;
          setEditPageReasons(reasons);

          let lCurrentDischargeReportForPage = lDestinationPortsForConsignment.find((dp) => dp.id === Number(consignmentDestinationPortID));

          // regulatory users can only view this page if the day the discharge report was competed has been submitted
          // ideally we would handle this at the database level, but this is difficult as the regulatory user still needs to know
          // the port exists even if it has not been submitted
          let lCanViewPage = true;
          if (userCtx.user?.userTypeID === 2) {
            if (lConsignmentDays.find(cd => cd.id === lCurrentDischargeReportForPage.consignmentDayID)) {
              lCanViewPage = true;
            }
            else {
              lCanViewPage = false;
            }
          }

          if (lCanViewPage) {
            if (lCurrentDischargeReportForPage) {
              setVesselArrived(new Date(lCurrentDischargeReportForPage.vesselArrivalDateUTC));
              setDischargeCommenced(new Date(lCurrentDischargeReportForPage.dischargeCommencedDateUTC));
              setDischargeCompleted(new Date(lCurrentDischargeReportForPage.dischargeCompletedDateUTC));
              updateIsReviewedStatus(lCurrentDischargeReportForPage.id, lCurrentDischargeReportForPage.isDischargeReviewed);
            }

            let lDisplayLoadingPorts = [];
            lDeparturePortsForConsignment = lDeparturePortsForConsignment.sort(
              (a, b) => new Date(a.vesselArrivalDateUTC) - new Date(b.vesselArrivalDateUTC)
            );
            lDeparturePortsForConsignment.forEach((port) => {
              let portDetails = lDeparturePorts.find((p) => Number(p.id) === Number(port.portID));
              lDisplayLoadingPorts.push(portDetails);
            });
            setDeparturePorts(lDisplayLoadingPorts);

            let lDisplayDestinationPorts = [];
            lDestinationPortsForConsignment = lDestinationPortsForConsignment.sort(
              (a, b) => new Date(a.vesselArrivalDateUTC) - new Date(b.vesselArrivalDateUTC)
            );
            lDestinationPortsForConsignment.forEach((port) => {
              let portDetails = lDestinationPorts.find((p) => Number(p.id) === Number(port.portID));
              portDetails.consignmentPortID = port.id;
              portDetails.vesselArrivalDateUTC = port.vesselArrivalDateUTC;
              portDetails.dayNumber = lConsignmentDays.find(cd => cd.id === lCurrentDischargeReportForPage.consignmentDayID).dayNumber;
              lDisplayDestinationPorts.push(portDetails);
            });
            setDestinationPorts(lDisplayDestinationPorts);
            return true;
          }
          else {
            return false;
          }
        } else {
          return false;
        }
      })
      .then((result) => {
        setHasData(result);
        setHasLoadError(!result);
        setIsLoading(false);
      })
      .catch((error) => {
        setHasLoadError(true);
        setIsLoading(false);
      });
  };

  const cancelEdit = () => {
    setIsEditMode(false);
    populatePage();
  };

  useEffect(() => {
    if (consignmentDestinationPortID && consignmentID && userCtx.user) {
      populatePage();
    }
  }, [consignmentDestinationPortID, consignmentID, userCtx.user]);

  useEffect(() => {
    if (dischargeCommenced && dischargeCompleted) {
      let lHours = differenceInHours(dischargeCompleted, dischargeCommenced);
      if (lHours === 1) {
        setDischargingForHours(lHours + " hour");
      } else {
        setDischargingForHours(lHours + " hours");
      }
    }
  }, [dischargeCommenced, dischargeCompleted]);

  useEffect(() => {
    const getDisplayLivestockDischarged = () => {
      let lCurrentDischarged = [...livestockDischarged].filter((obj) => obj.consignmentDestinationPortID === Number(consignmentDestinationPortID));
      const lCurrentPort = [...destinationPorts].find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID));

      // get destination ports before the departure port being viewed
      let lPreviousDischargePorts = [...destinationPorts].filter(
        (dp) => new Date(dp.vesselArrivalDateUTC) < new Date(lCurrentPort.vesselArrivalDateUTC)
      );
      let lPreviousDischargePortsIDs = [...new Set(lPreviousDischargePorts.map((item) => item.consignmentPortID))];

      // get previous destination ports livestock discharged
      let lPrevLivestockDischarge = [...livestockDischarged].filter((obj) => lPreviousDischargePortsIDs.includes(obj.consignmentDestinationPortID));

      let lLivestockDischargedDisplay = [];

      livestockLoaded.forEach((aLivestockLoaded) => {
        let lLoaded = aLivestockLoaded.numberLoaded;
        let lCurrentLivestockDischarged = lCurrentDischarged.find(
          (ld) => ld.speciesID === aLivestockLoaded.speciesID && ld.classID === aLivestockLoaded.classID
        );

        let lCurrentLivestockDischargedCount = 0;
        if (lCurrentLivestockDischarged) {
          lCurrentLivestockDischargedCount = lCurrentLivestockDischarged.numberDischarged;
        }

        let lPrevDischarged = [...lPrevLivestockDischarge].filter(
          (pd) => pd.speciesID === aLivestockLoaded.speciesID && pd.classID === aLivestockLoaded.classID
        );
        lPrevDischarged = lPrevDischarged.reduce((total, obj) => total + obj.numberDischarged, 0);

        let lMortalities = 0;
        if (consignmentMortalities) {
          // filter on species, class and day number
          let lSpeciesMortalities = [...consignmentMortalities].filter(
            (mort) => mort.speciesID === aLivestockLoaded.speciesID 
            && mort.classID === aLivestockLoaded.classID
            && mort.dayNumber <= lCurrentPort.dayNumber
          );
          if (lSpeciesMortalities) {
            lMortalities = lSpeciesMortalities.length;
          }
        }

        let lCurrentlyOnVesselAtPortArrival = lLoaded - lPrevDischarged - lMortalities;
        lLivestockDischargedDisplay.push({
          id: lCurrentLivestockDischarged?.id,
          species: aLivestockLoaded.speciesName,
          class: aLivestockLoaded.className,
          totalLoaded: lLoaded,
          prevDischarged: lPrevDischarged,
          mortalities: lMortalities,
          currentOnVessel: lCurrentlyOnVesselAtPortArrival,
          discharged: lCurrentLivestockDischargedCount,
        });
      });

      setLivestockDischargedDisplay(lLivestockDischargedDisplay);
    };

    getDisplayLivestockDischarged();
  }, [livestockLoaded, livestockDischarged, consignmentMortalities]);

  useEffect(() => {
    const getDisplayLivestockDischargeSummary = () => {
      let lSummary = [];

      destinationPorts.forEach((port) => {
        let lDestinationPortDischarge = { portName: port.portName, portCountry: port.countryName, livestockDischarged: [], totalDischarged: 0 };
        let lLivestockDischargedAtPort = [...livestockDischarged].filter(
          (livestock) => livestock.consignmentDestinationPortID === port.consignmentPortID
        );

        let lTotalDischarged = 0;

        consignmentSpecies.forEach((species) => {
          let lNumDischarged = "-";

          let lSpeciesDischarged = [...lLivestockDischargedAtPort].filter((ls) => ls.speciesID === species.id);
          if (lSpeciesDischarged) {
            lNumDischarged = lSpeciesDischarged.reduce((total, obj) => total + Number(obj.numberDischarged), 0);
            lTotalDischarged += lNumDischarged;
          }

          lDestinationPortDischarge.livestockDischarged.push({ speciesID: species.id, numberDischarged: lNumDischarged });
        });

        lDestinationPortDischarge.totalDischarged = lTotalDischarged;
        lSummary.push(lDestinationPortDischarge);
      });

      setLivestockDischargedSummary(lSummary);
    };

    getDisplayLivestockDischargeSummary();
  }, [livestockDischarged, consignmentSpecies, destinationPorts]);

  const updateLivestockDischarged = (id, numberDischarged) => {
    let lUpdatedDischarge = [...livestockDischarged];
    let lMatch = lUpdatedDischarge.find((obj) => obj.id === id);
    if (lMatch) {
      lMatch.numberDischarged = numberDischarged;
    }
    setLivestockDischarged(lUpdatedDischarge);
  };

  const onEditModalClosed = () => {
    setEditModalShow(false);
  };

  const onEditModalSaveSuccess = () => {
    save();
  };

  const onEditModalSaveFailed = () => {
    // do nothing generic error message is displayed inside the modal
  };

  const validateAndShowEditModal = (e) => {
    const validate = () => {
      // Dates must not be null or empty
      // Discharge commenced > arrival date
      // Discharge completed > discharge commenced
      let lArrivalDateValid = !StringIsNullUndefinedOrEmpty(vesselArrived);
      setVesselArrivedDateValid(lArrivalDateValid);

      let lDischargeCommencedDateValid = false;
      if (!StringIsNullUndefinedOrEmpty(dischargeCommenced)) {
        if (lArrivalDateValid && differenceInMinutes(dischargeCommenced, vesselArrived) <= 0) {
          lDischargeCommencedDateValid = false;
          setDischargeCommencedDateErrorMessage("The discharge commenced date must be after the vessel arrival date.");
        } else {
          setDischargeCommencedDateErrorMessage("");
          lDischargeCommencedDateValid = true;
        }
      } else {
        setDischargeCommencedDateErrorMessage("A discharge commenced date and time is required.");
        lDischargeCommencedDateValid = false;
      }
      setdischargingCommencedDateValid(lDischargeCommencedDateValid);

      let lDischargeCompletedDateValid = false;
      if (!StringIsNullUndefinedOrEmpty(dischargeCompleted)) {
        if (lArrivalDateValid && differenceInMinutes(dischargeCompleted, dischargeCommenced) <= 0) {
          lDischargeCompletedDateValid = false;
          setDischargeCompletedDateErrorMessage("The discharge completed date must be after the discharge commenced date.");
        } else {
          setDischargeCompletedDateErrorMessage("");
          lDischargeCompletedDateValid = true;
        }
      } else {
        setDischargeCompletedDateErrorMessage("A discharge compleetd date and time is required.");
        lDischargeCompletedDateValid = false;
      }
      setdischargingCompletedDateValid(lDischargeCompletedDateValid);

      if (lArrivalDateValid && lDischargeCommencedDateValid && lDischargeCompletedDateValid) {
        return true;
      } else {
        return false;
      }
    };

    e.preventDefault();
    setHasSaveError(false);
    setHasSaveSuccess(false);

    let lValid = validate();

    if (lValid === true) {
      setEditModalShow(true);
    } else {
      setEditModalShow(false);
      setHasSaveError(true);
      setHasSaveErrorMessage("Unable to save discharge report. Please address any validation issues.");
    }
  };

  // at this point the page should have already been validated
  const save = () => {
    const writeToDb = async () => {
      setEditModalShow(false);

      // create object to save
      let lUpdatedDishchargeAnimals = [];
      livestockDischarged.forEach((livestock) => {
        lUpdatedDishchargeAnimals.push(new DischargeReportLivestockDischarged(livestock.id, Number(livestock.numberDischarged)));
      });

      let lDischargeReportObj = new DischargeReportSave(
        vesselArrived.toISOString(),
        dischargeCommenced.toISOString(),
        dischargeCompleted.toISOString(),
        lUpdatedDishchargeAnimals
      );

      let lResult = await patchDischargeReport(consignmentDestinationPortID, lDischargeReportObj);
      if (lResult?.status === 200) {
        setHasSaveSuccess(true);
        setTimeout(() => {
          setHasSaveSuccess(false);
        }, 15000);
        setIsEditMode(false);
      } else {
        setHasSaveError(true);
        setHasSaveErrorMessage("Something went wrong while trying to save.");
      }
    };
    writeToDb();
  };

  return (
    <LoggedInContainer>
      <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
        <Row className="upper-panel mx-0" style={{ position: "relative" }}>
          <Col>
            <Container>
              <Row ref={headerContainerRef} style={{ marginTop: mediaQueryCtx.mobile === true ? "40px" : "65px" }}>
                {
                  mediaQueryCtx.mobile === true ? (
                    <>
                      <Col xs={12}>
                        <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                          <div style={{ lineHeight: "53px", fontSize: "35px" }}>Discharge Report</div>
                          <div>LNC- {lncNumber}</div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <Button className="w-100 mt-2" variant="back" onClick={() => goBack()}>
                          <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />
                          Back
                        </Button>
                      </Col>
                      
                      <Col xs={12} className="mt-3" style={{ textAlign: "right" }}>
                        {loading === false && isEditMode === false ? (
                          <ReadWriteButton className="w-100" writeonly={true} variant="back" onClick={() => setIsEditMode(true)}>
                            Edit Discharge
                          </ReadWriteButton>
                        ) : null}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={2}>
                        <Button variant="back" onClick={() => goBack()}>
                          <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />
                          Back
                        </Button>
                      </Col>
                      <Col>
                        <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                          <div style={{ lineHeight: "53px", fontSize: "35px" }}>Discharge Report</div>
                          <div>LNC- {lncNumber}</div>
                        </div>
                      </Col>
                      <Col xs={2} style={{ textAlign: "right" }}>
                        {loading === false && isEditMode === false ? (
                          <ReadWriteButton writeonly={true} variant="back" onClick={() => setIsEditMode(true)}>
                            Edit Discharge
                          </ReadWriteButton>
                        ) : null}
                      </Col>
                    </>
                  )
                }
                
              </Row>
            </Container>
          </Col>
        </Row>
        <Container className="py-4 px-5 form-container-full" style={{marginTop: headerContainerRef?.current?.offsetHeight ? headerContainerRef.current.offsetHeight - 120 : 0}}>
          {loading === true ? (
            <Row>
              <Col>
                <div className="text-center pt-5">
                  <Spinner className="mx-auto" />
                  <div className="mx-auto">Loading data...</div>
                </div>
              </Col>
            </Row>
          ) : null}
          {hasData === true && loading === false ? (
            <>
              <Row className="mb-4">
                <Col>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <div className="green-underline-heading">Voyage Summary</div>
                    </Col>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        <div className="field-label">Loaded In</div>
                        {departurePorts.map((port, i) => {
                          return (
                            <div key={`departurePort_${port.id}`} className="text-13-400 secondary-grey">
                              {port.portName}
                            </div>
                          );
                        })}
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="field-label">Discharging In</div>
                        {destinationPorts.map((port, i) => {
                          return (
                            <div
                              className={`secondary-grey ${port.consignmentPortID === Number(consignmentDestinationPortID) ? "bold text-13" : "text-13-400"
                                }`}
                              key={`destinationPort_${port.id}`}
                            >
                              {port.portName}, {port.countryName}
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="mb-4">
                        <Stack>
                          <div className="field-label">Vessel Arrived</div>
                          <div className="text-13-400 secondary-grey">
                            {isEditMode === false ? (
                              <>
                                <div className="text-13-400 secondary-grey">
                                  {getPortLocalDatetime(
                                    destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID)),
                                    vesselArrived
                                  )}
                                </div>
                                <div className="text-13-400 secondary-grey">
                                  {getPortDateTimeUTC(
                                    destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID)),
                                    vesselArrived
                                  ) + " UTC"}
                                </div>
                              </>
                            ) : (
                              <DateTimePicker
                                port={destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID))}
                                datetime={vesselArrived}
                                isValid={vesselArrivedDateValid}
                                errorMessage={"A vessel arrival date is required."}
                                showTime={true}
                                showUTCTime={true}
                                onChange={(date) => {
                                  setVesselArrivedDateValid(true);
                                  setVesselArrived(date);
                                }}
                                showHeadLabel={false}
                                endLabel="Local Time"
                              />
                            )}
                          </div>
                        </Stack>
                      </Col>
                      <Col xs={12} className="mb-4">
                        <Stack>
                          <div className="field-label">Discharging Commenced</div>
                          <div className="text-13-400 secondary-grey">
                            {isEditMode === false ? (
                              <>
                                <div className="text-13-400 secondary-grey">
                                  {getPortLocalDatetime(
                                    destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID)),
                                    dischargeCommenced
                                  )}
                                </div>
                                <div className="text-13-400 secondary-grey">
                                  {getPortDateTimeUTC(
                                    destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID)),
                                    dischargeCommenced
                                  ) + " UTC"}
                                </div>
                              </>
                            ) : (
                              <DateTimePicker
                                port={destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID))}
                                datetime={dischargeCommenced}
                                isValid={dischargingCommencedDateValid}
                                errorMessage={dischargeCommencedDateErrorMessage}
                                showTime={true}
                                showUTCTime={true}
                                onChange={(date) => {
                                  setdischargingCommencedDateValid(true);
                                  setDischargeCommenced(date);
                                }}
                                showHeadLabel={false}
                                endLabel="Local Time"
                              />
                            )}
                          </div>
                        </Stack>
                      </Col>
                      <Col xs={12} className="mb-4">
                        <Stack>
                          <div className="field-label">Discharging Completed</div>
                          <div className="text-13-400 secondary-grey">
                            {isEditMode === false ? (
                              <>
                                <div className="text-13-400 secondary-grey">
                                  {getPortLocalDatetime(
                                    destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID)),
                                    dischargeCompleted
                                  )}
                                </div>
                                <div className="text-13-400 secondary-grey">
                                  {getPortDateTimeUTC(
                                    destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID)),
                                    dischargeCompleted
                                  ) + " UTC"}
                                </div>
                              </>
                            ) : (
                              <DateTimePicker
                                port={destinationPorts.find((dp) => dp.consignmentPortID === Number(consignmentDestinationPortID))}
                                datetime={dischargeCompleted}
                                isValid={dischargingCompletedDateValid}
                                errorMessage={dischargeCompletedDateErrorMessage}
                                showTime={true}
                                showUTCTime={true}
                                onChange={(date) => {
                                  setdischargingCompletedDateValid(true);
                                  setDischargeCompleted(date);
                                }}
                                showHeadLabel={false}
                                endLabel="Local Time"
                              />
                            )}
                          </div>
                        </Stack>
                      </Col>
                      <Col xs={12}>
                        {dischargeCommenced && dischargeCompleted ? (
                          <div className="italic text-13-400 secondary-grey">Discharging for {dischargingForHours}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <Row>
                    <Col xs={12}>
                      <div className="green-underline-heading">Livestock Discharged At This Port</div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      {mediaQueryCtx.mobile === true ? (
                        <>
                        {
                          livestockDischargedDisplay.map((livestock, i) => {
                            let greyBG = "#A3A3A31F";
                            return(
                              <Container fluid className="mb-3">
                                <Row>
                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={12} style={{fontWeight: "bold"}}>{`${livestock.species}-${livestock.class}`}</Col>
                                    </Row>
                                    <Row style={{backgroundColor: greyBG}}>
                                      <Col xs={6} className="field-label darkblue align-bottom py-1">Total Loaded</Col>
                                      <Col xs={6} className="field-label align-bottom py-1">{livestock.totalLoaded}</Col>
                                    </Row>
                                    <Row>
                                      <Col xs={6} className="field-label darkblue align-bottom py-1">Previously discharged</Col>
                                      <Col xs={6} className="field-label align-bottom py-1">{livestock.prevDischarged}</Col>
                                    </Row>
                                    <Row style={{backgroundColor:greyBG }}>
                                      <Col xs={6} className="field-label darkblue align-bottom py-1">Mortalities</Col>
                                      <Col xs={6} className="field-label align-bottom py-1">{livestock.mortalities}</Col>
                                    </Row>
                                    <Row>
                                      <Col xs={6} className="field-label darkblue align-bottom py-1">Currently on Vessel Upon Arrival at Port</Col>
                                      <Col xs={6} className="field-label align-bottom py-1">{livestock.currentOnVessel}</Col>
                                    </Row>
                                    <Row style={{backgroundColor: greyBG }}>
                                      <Col xs={6} className="field-label darkblue align-bottom py-1">No. Discharged</Col>
                                      <Col xs={6} className="field-label align-bottom py-1">{livestock.discharged}</Col>
                                    </Row>
                                  </Col>
                                </Row>
                            </Container>
                            )
                          })
                        }
                        </>
                      ) :
                      (
                        <Row>
                          <Col xs={12} md={9}>
                            <Row className="pb-1">
                              <Col className="field-label darkblue align-bottom">Species / Class</Col>
                              <Col className="field-label darkblue align-bottom text-center">Total Loaded</Col>
                              <Col className="field-label darkblue align-bottom text-center">Previously Discharged</Col>
                              <Col className="field-label darkblue align-bottom text-center">Mortalities</Col>
                              <Col className="field-label darkblue align-bottom text-center">Currently on Vessel Upon Arrival at Port</Col>
                              <Col className="field-label darkblue align-bottom text-center">No. Discharged</Col>
                            </Row>
                            {livestockDischargedDisplay.map((livestock, i) => {
                              return (
                                <Row key={`livestock_discharged_${i}`} className="pb-2" style={{ alignItems: "center" }}>
                                  <Col className="text-13-400 secondary-grey">
                                    <div>{`${livestock.species}-${livestock.class}`}</div>
                                  </Col>
                                  <Col className="text-13-400 secondary-grey text-end">
                                    <div style={{ paddingRight: "25px" }}>{livestock.totalLoaded}</div>
                                  </Col>
                                  <Col className="text-13-400 secondary-grey text-end">
                                    <div style={{ paddingRight: "30px" }}>{livestock.prevDischarged}</div>
                                  </Col>
                                  <Col className="text-13-400 secondary-grey text-end">
                                    <div style={{ paddingRight: "35px" }}>{livestock.mortalities}</div>
                                  </Col>
                                  <Col className="text-13-400 secondary-grey text-end">
                                    <div style={{ paddingRight: "5px" }}>{livestock.currentOnVessel}</div>
                                  </Col>
                                  <Col className="text-13-400 secondary-grey text-end">
                                    {isEditMode === false ? (
                                      <div style={{ paddingRight: "17px" }}>{livestock.discharged}</div>
                                    ) : (
                                      <Form.Group>
                                        <Form.Control
                                          id={`livestock_discharge_input_${i}`}
                                          className="input"
                                          value={livestock.discharged}
                                          onChange={(e) => {
                                            parseNumericInput(
                                              e,
                                              new NumericValidationRules({
                                                allowDecimal: false,
                                                allowNegative: false,
                                                allowLeadingZerosForWholeNumbers: false,
                                                allowSingleZero: true,
                                                minRange: 0,
                                                maxRange: livestock.currentOnVessel,
                                              })
                                            );
                                            updateLivestockDischarged(livestock.id, e.target.value);
                                          }}
                                        />
                                      </Form.Group>
                                    )}
                                  </Col>
                                </Row>
                              );
                          })}
                        </Col>
                      </Row>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12}>
                      <div className="green-underline-heading">Livestock Discharge Summary</div>
                    </Col>
                  </Row>
                  {
                    mediaQueryCtx.mobile === true ? (
                      <>
                        {
                            livestockDischargedSummary.map((summary, i) => {
                              let greyBG = "#A3A3A31F";
                              return (
                                <Container fluid className="mb-3 faint-underline-grey">
                                  <Row>
                                    <Col xs={12} style={{fontWeight: "bold"}}>{`${summary.portName}-${summary.portCountry}`}</Col>
                                  </Row>
                                  <Row  style={{backgroundColor: greyBG}}>
                                    <Col xs={6} className="field-label darkblue align-bottom py-1">Total Discharged</Col>
                                    <Col xs={6} className="field-label align-bottom py-1">{summary.totalDischarged}</Col>
                                  </Row>
                                  {
                                    consignmentSpecies.map((species, i) => {
                                      return (
                                        <Row  style={{backgroundColor: i % 2 ? greyBG : "inherit"}}>
                                          <Col xs={6} className="field-label darkblue align-bottom py-1" key={`species_heading_${i}`}>
                                            {species.speciesName} discharged
                                          </Col>
                                          <Col xs={6} className="field-label align-bottom py-1" key={`species_summary_date_${i}`}>
                                            {summary.livestockDischarged.find((obj) => obj.speciesID === species.id).numberDischarged}
                                          </Col>
                                        </Row>
                                      )
                                    })
                                  }
                                </Container>
                            )})
                        }
                      </>
                    ): 
                    (
                      <>
                        <Row>
                          <Col xs={2} className="field-label align-bottom">
                            Port
                          </Col>
                          <Col xs={2} className="field-label align-bottom text-center">
                            Total Discharged
                          </Col>
                          {consignmentSpecies.map((species, i) => {
                            return (
                              <Col xs={1} key={`species_heading_${i}`} className="field-label align-bottom text-center">
                                {species.speciesName}
                              </Col>
                            );
                          })}
                        </Row>
                        {
                          livestockDischargedSummary.map((summary, i) => {
                            return (
                              <Row key={`summary_port_${i}`} className="pb-2">
                                <Col xs={2} className="text-13-400 secondary-grey">{`${summary.portName}-${summary.portCountry}`}</Col>
                                <Col xs={2} className="text-13-400 secondary-grey text-end">
                                  <div style={{ paddingRight: "40px" }}>{summary.totalDischarged}</div>
                                </Col>
                                {consignmentSpecies.map((species, i) => {
                                  let lPortSpecies = summary.livestockDischarged.find((obj) => obj.speciesID === species.id);
                                  return (
                                    <Col xs={1} key={`species_summary_date_${i}`} className="text-13-400 secondary-grey text-end">
                                      <div style={{ paddingRight: "20px" }}>{lPortSpecies.numberDischarged}</div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            );
                          })
                        }
                      </>
                    )
                  }
                </Col>
              </Row>
            </>
          ) : null}
          {loadError === true && loading === false ? (
            <Container>
              <Row>
                <Col xs={12}>
                  <Alert variant="danger">Unable to load discharge report.</Alert>
                </Col>
                <Col xs={12}>
                  <Button
                    className="me-2 ms-auto"
                    variant="primary"
                    onClick={() => {
                      goBack();
                    }}
                    size="lg"
                    style={{ minWidth: "150px" }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </Container>
          ) : null}
        </Container>

        {loading === false && hasData === true ? (
          <Row className="mt-5 mx-0" style={{ position: "relative", top: "-300px" }}>
            {saveSuccess === true ? (
              <Col xs={12}>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <Alert variant="success">Discharge report updated successfully.</Alert>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ) : null}

            {saveError === true ? (
              <Col xs={12}>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <Alert variant="danger">{saveErrorMessage}</Alert>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ) : null}

            {isEditMode === true ? (
              <Col xs={12}>
                <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      cancelEdit();
                    }}
                    size="lg"
                    style={{ minWidth: "150px" }}
                  >
                    Cancel
                  </Button>
                  <ReadWriteButton
                    writeonly={true}
                    variant="primary"
                    onClick={(e) => {
                      validateAndShowEditModal(e);
                    }}
                    size="lg"
                    style={{ minWidth: "150px" }}
                  >
                    Update
                  </ReadWriteButton>
                </Stack>
              </Col>
            ) : (
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      goBack();
                    }}
                    size="lg"
                    style={{ minWidth: "150px" }}
                  >
                    Done
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        ) : null}
      </Container>
      <EditLogModal
        title="Edit Discharge Report"
        page="Discharge Report"
        userID={userCtx.user.id}
        consignmentIDs={[consignmentID]}
        showModal={editModalShow}
        reasonOptions={editPageReasons}
        onClose={onEditModalClosed}
        onSaveSuccess={onEditModalSaveSuccess}
        onSaveFail={onEditModalSaveFailed}
      />
    </LoggedInContainer>
  );
};

export default Discharge_Report;
