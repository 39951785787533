import React, { useState, useEffect, useContext } from "react";
import { Container, Stack, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowRight, faArrowLeft, faHourglass1 } from "@fortawesome/free-solid-svg-icons";
import { getAllConsignmentEventReportsForConsignmentID } from "../../api/apiAccess";
import { useNavigate } from "react-router-dom";
import { ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox } from "../Status/ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox";
import { sortByProperty } from "../../utils/ListUtils";
import tz_lookup from 'tz-lookup';
import { formatInTimeZone } from 'date-fns-tz';
import { UserContext } from "../..";
import { UserType } from "../../api/enums/EnumUserType";

const ConsignmentVoyageSummary = ({ consignmentID, isConsignmentEditable, isMobile, isHistorical }) => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);

    const [startIndex, setStartIndex] = useState(0);
    const [allConsignmentEventReports, setAllConsignmentEventReports] = useState([]);
    const [filteredConsignmentEventReports, setFilteredConsignmentEventReports] = useState([]);
    const [prevEnabled, setPrevEnabled] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);

    const [columnWidthPercentage, setColumnWidthPercentage] = useState(100);
    const maxVisibleColumnNumber = isMobile ? 1 : 4;

    const [anyDayHasLoadingReport, setAnyDayHasLoadingReport] = useState(false);
    const [anyDayHasDailyReport, setAnyDayHasDailyReport] = useState(false);
    const [anyDayHasDischargeReport, setAnyDayHasDischargeReport] = useState(false);
    const [anyDayHasEOVReport, setAnyDayHasEOVReport] = useState(false);

    useEffect(() => {
        if (consignmentID === undefined) return;

        const fetchAllConsignmentEventReports = async () => {
            let results = await getAllConsignmentEventReportsForConsignmentID(consignmentID);

            if (results?.status === 200) {
                if (!results.data[0]) return;
                let lSorted = sortByProperty(results.data, "dayNumber");
                setAllConsignmentEventReports(lSorted);
            }
        }

        const fetchAll = async () => {
            await fetchAllConsignmentEventReports();
        }

        fetchAll();
    }, [consignmentID])


    useEffect(() => {
        let lFiltered = [];
        let lColumnWidth = 100;

        if (allConsignmentEventReports && allConsignmentEventReports.length > 0) {
            lFiltered = [...allConsignmentEventReports].filter((report, index) => index >= startIndex && index <= (startIndex + maxVisibleColumnNumber - 1));
            lColumnWidth = 100 / allConsignmentEventReports.length;
        }

        setFilteredConsignmentEventReports(lFiltered);
        setColumnWidthPercentage(lColumnWidth);
    }, [allConsignmentEventReports, startIndex, maxVisibleColumnNumber])

    useEffect(() => {
        // if any day (currently displayed on screen) has x report type we need to show a row for that report type so thatt report types align between days
        const checkIfReportTypeExistsForAnyDay = () => {
            let lAnyHasDailyReport = false;
            let lAnyHasLoadingReport = false;
            let lAnyHasDischargeReport = false;
            let lAnyHasEOVReport = false;

            if (filteredConsignmentEventReports && filteredConsignmentEventReports.length > 0) {
                lAnyHasDailyReport = filteredConsignmentEventReports.some(day => day.observationReportEvents && day.observationReportEvents.length > 0);
                lAnyHasLoadingReport = filteredConsignmentEventReports.some(day => day.loadingReportEvents && day.loadingReportEvents.length > 0);
                lAnyHasDischargeReport = filteredConsignmentEventReports.some(day => day.dischargeReportEvents && day.dischargeReportEvents.length > 0);
                lAnyHasEOVReport = filteredConsignmentEventReports.some(day => day.eovReportEvent);
            }

            setAnyDayHasDailyReport(lAnyHasDailyReport);
            setAnyDayHasLoadingReport(lAnyHasLoadingReport);
            setAnyDayHasDischargeReport(lAnyHasDischargeReport);
            setAnyDayHasEOVReport(lAnyHasEOVReport);
        }
        checkIfReportTypeExistsForAnyDay();
    }, [filteredConsignmentEventReports])

    useEffect(() => {
        if (allConsignmentEventReports.length > maxVisibleColumnNumber) {
            setNextEnabled(true);
        }
    }, [allConsignmentEventReports])

    const scrollPrev = () => {
        if (startIndex === 0) return;
        let targetIndex = startIndex - 1;
        if (targetIndex < 0) targetIndex = 0;
        setPrevEnabled(targetIndex === 0);
        setStartIndex(targetIndex);
    }

    const scrollNext = () => {
        if (startIndex === allConsignmentEventReports.length - maxVisibleColumnNumber) return;
        let targetIndex = startIndex + 1;
        if (targetIndex > allConsignmentEventReports.length - maxVisibleColumnNumber) targetIndex = allConsignmentEventReports.length - maxVisibleColumnNumber;
        setNextEnabled(targetIndex === allConsignmentEventReports.length - maxVisibleColumnNumber);
        setStartIndex(targetIndex);
    }

    const getLocalDatetimeOfReport = (report) => {
        // LX-1489: For regulatory users the date needs to be in Sydney timezone
        const timezone = userCtx.user?.userTypeID === UserType.REGULATORY ? 'Australia/Sydney' : tz_lookup(report.latitude, report.longitude);
        
        const dt = formatInTimeZone(report?.endDateUTC, timezone, "dd/MM/yyyy hh:mm a");
        return dt;
    }

    return (
        <Container className="px-4">
            {
                filteredConsignmentEventReports && filteredConsignmentEventReports.length > 0 ?
                    <>
                        <Row className="bg-grey" style={{ borderRadius: '4px 4px 0 0' }}>
                            {
                                filteredConsignmentEventReports.map((report, i) => {
                                    let lBorderTopLeftRadius = 0;
                                    let lBorderTopRightRadius = 0;

                                    if (anyDayHasEOVReport === false) {
                                        if (i === 0) {
                                            lBorderTopLeftRadius = 4;
                                        }
                                        if (i === filteredConsignmentEventReports.length - 1) {
                                            lBorderTopRightRadius = 4;
                                        }
                                    }
                                    return (
                                        <Col className="py-2" key={`voyage_summary_header_col_${i}`}
                                            style={{
                                                width: `${columnWidthPercentage}%`,
                                                borderTop: '1px solid #DFE0E4',
                                                borderLeft: i === 0 ? '1px solid #DFE0E4' : 'none',
                                                borderRight: '1px solid #DFE0E4', borderBottom: '1px solid #DFE0E4',
                                                borderTopLeftRadius: `${lBorderTopLeftRadius}px`,
                                                borderTopRightRadius: `${lBorderTopRightRadius}px`
                                            }}>
                                            <div className="field-label">Day {report?.dayNumber}</div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        {
                            anyDayHasDailyReport === true ?
                                <Row>
                                    {
                                        filteredConsignmentEventReports.map((report, i) => {
                                            let lBorderBottomLeftRadius = 0;
                                            let lBorderBottomRightRadius = 0;

                                            if (anyDayHasLoadingReport === false) {
                                                if (i === 0) {
                                                    lBorderBottomLeftRadius = 4;
                                                }
                                                if (i === filteredConsignmentEventReports.length - 1) {
                                                    lBorderBottomRightRadius = 4;
                                                }
                                            }
                                            return (
                                                <Col className="py-1" key={`obs_report_${i}`}
                                                    style={{
                                                        width: `${columnWidthPercentage}%`,
                                                        borderLeft: i === 0 ? '1px solid #DFE0E4' : 'none',
                                                        borderRight: '1px solid #DFE0E4',
                                                        borderBottom: '1px solid #DFE0E4',
                                                        borderBottomLeftRadius: `${lBorderBottomLeftRadius}px`,
                                                        borderBottomRightRadius: `${lBorderBottomRightRadius}px`
                                                    }}>
                                                    {
                                                        report?.observationReportEvents?.map((obsRep, idx) => {
                                                            let lPaddingBottom = 'pb-0';
                                                            report.observationReportEvents && report.observationReportEvents.length > 1
                                                                && idx !== report.observationReportEvents.length - 1 ?
                                                                lPaddingBottom = 'pb-1'
                                                                : lPaddingBottom = 'pb-0';
                                                            return (
                                                                <Row key={`obs_list_${idx}`}
                                                                    className={`${lPaddingBottom} clickable report-link-cell`}
                                                                    onClick={() => {
                                                                        navigate(`/viewhistorydata/${consignmentID}`, { state: { focusedTab: "observations", selectedColumnIndex: i + 2, editable: isConsignmentEditable, isHistorical: isHistorical } })
                                                                    }}>
                                                                    <Col>
                                                                        <Stack direction="horizontal" gap={2}>
                                                                            <ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox isViewed={report.dailyReportingIsViewed} hasReport={true} />
                                                                            <span className="field-label">Daily Reporting</span>
                                                                            <span className="field-label secondary-grey">{getLocalDatetimeOfReport(report)}</span>
                                                                        </Stack>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                : null
                        }
                        {
                            anyDayHasLoadingReport === true ?
                                <Row>
                                    {
                                        filteredConsignmentEventReports.map((report, i) => {
                                            let lBorderBottomLeftRadius = 0;
                                            let lBorderBottomRightRadius = 0;

                                            if (anyDayHasDischargeReport === false) {
                                                if (i === 0) {
                                                    lBorderBottomLeftRadius = 4;
                                                }
                                                if (i === filteredConsignmentEventReports.length - 1) {
                                                    lBorderBottomRightRadius = 4;
                                                }
                                            }
                                            return (
                                                <Col className="py-1" key={`loading_report_${i}`}
                                                    style={{
                                                        width: `${columnWidthPercentage}%`,
                                                        borderLeft: i === 0 ? '1px solid #DFE0E4' : 'none',
                                                        borderRight: '1px solid #DFE0E4',
                                                        borderBottom: '1px solid #DFE0E4',
                                                        borderBottomLeftRadius: `${lBorderBottomLeftRadius}px`,
                                                        borderBottomRightRadius: `${lBorderBottomRightRadius}px`
                                                    }}>
                                                    {
                                                        report.loadingReportEvents?.map((depAndLoadRep, idx) => {
                                                            let lPaddingBottom = 'pb-0';
                                                            report.loadingReportEvents && report.loadingReportEvents.length > 1
                                                                && idx !== report.loadingReportEvents.length - 1 ?
                                                                lPaddingBottom = 'pb-1'
                                                                : lPaddingBottom = 'pb-0';
                                                            return (
                                                                <Row key={`departure_report_${idx}`}
                                                                    className={`${lPaddingBottom} clickable report-link-cell`}
                                                                    onClick={() => navigate(`/voyagedepartureandvesselloading/${depAndLoadRep.id}`)}>
                                                                    <Col>
                                                                        <Stack direction="horizontal" gap={2}>
                                                                            <ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox isViewed={depAndLoadRep.isViewed} hasReport={true} />
                                                                            <span className="field-label">Departure and Loading Report - {depAndLoadRep.portName}</span>
                                                                        </Stack>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                : null
                        }
                        {
                            anyDayHasDischargeReport === true ?
                                <Row>
                                    {
                                        filteredConsignmentEventReports.map((report, i) => {
                                            let lBorderBottomLeftRadius = 0;
                                            let lBorderBottomRightRadius = 0;

                                            if (anyDayHasEOVReport === false) {
                                                if (i === 0) {
                                                    lBorderBottomLeftRadius = 4;
                                                }
                                                if (i === filteredConsignmentEventReports.length - 1) {
                                                    lBorderBottomRightRadius = 4;
                                                }
                                            }
                                            return (
                                                <Col className="py-1" key={`discharge_report_${i}`}
                                                    style={{
                                                        width: `${columnWidthPercentage}%`,
                                                        borderLeft: i === 0 ? '1px solid #DFE0E4' : 'none',
                                                        borderRight: '1px solid #DFE0E4',
                                                        borderBottom: '1px solid #DFE0E4',
                                                        borderBottomLeftRadius: `${lBorderBottomLeftRadius}px`,
                                                        borderBottomRightRadius: `${lBorderBottomRightRadius}px`
                                                    }}>
                                                    {
                                                        report.dischargeReportEvents?.map((disRep, idx) => {
                                                            let lPaddingBottom = 'pb-0';
                                                            report.dischargeReportEvents && report.dischargeReportEvents.length > 1
                                                                && idx !== report.dischargeReportEvents.length - 1 ?
                                                                lPaddingBottom = 'pb-1'
                                                                : lPaddingBottom = 'pb-0';
                                                            return (
                                                                <Row key={`discharge_report_${idx}`} className={`${lPaddingBottom} clickable report-link-cell`} onClick={() => navigate(`/dischargereport/${consignmentID}/${disRep.id}`)}>
                                                                    <Col>
                                                                        <Stack direction="horizontal" gap={2}>
                                                                            <ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox isViewed={disRep.isViewed} hasReport={true} />
                                                                            <span className="field-label">Discharge Report - {disRep.portName}</span>
                                                                        </Stack>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                : null
                        }
                        {
                            anyDayHasEOVReport === true ?
                                <Row>
                                    {
                                        filteredConsignmentEventReports.map((report, i) => {
                                            let lBorderBottomLeftRadius = 0;
                                            let lBorderBottomRightRadius = 0;

                                            if (i === 0) {
                                                lBorderBottomLeftRadius = 4;
                                            }
                                            if (i === filteredConsignmentEventReports.length - 1) {
                                                lBorderBottomRightRadius = 4;
                                            }

                                            return (
                                                <Col className="py-1" key={`discharge_report_${i}`}
                                                    style={{
                                                        width: `${columnWidthPercentage}%`,
                                                        borderLeft: i === 0 ? '1px solid #DFE0E4' : 'none',
                                                        borderRight: '1px solid #DFE0E4',
                                                        borderBottom: '1px solid #DFE0E4',
                                                        borderBottomLeftRadius: `${lBorderBottomLeftRadius}px`,
                                                        borderBottomRightRadius: `${lBorderBottomRightRadius}px`
                                                    }}>
                                                    {
                                                        report.eovReportEvent ?
                                                            <Row key={`eov_report_${report.eovReportEvent.id}`} className="clickable report-link-cell" onClick={() => navigate(`/endofvoyagereport/${consignmentID}`)}>
                                                                <Col>
                                                                    <Stack direction="horizontal" gap={2}>
                                                                        <ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox isViewed={report.eovReportEvent.isViewed} hasReport={true} />
                                                                        <span className="field-label">End of Voyage Report</span>
                                                                    </Stack>
                                                                </Col>
                                                            </Row>
                                                            : null
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                : null
                        }
                        {
                            allConsignmentEventReports.length > maxVisibleColumnNumber ? (
                                <Row className="pt-2">
                                    <Stack direction="horizontal" style={{ justifyContent: 'space-between' }}>
                                        <Button
                                            onClick={() => {
                                                scrollPrev();
                                            }}
                                            enabled={prevEnabled}
                                        >
                                            <div>
                                                <span><FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Prev</span>
                                            </div>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                scrollNext();
                                            }}
                                            enabled={nextEnabled}
                                        >
                                            <div>
                                                <span>Next&nbsp; <FontAwesomeIcon icon={faArrowRight} /></span>
                                            </div>
                                        </Button>
                                    </Stack>
                                </Row>
                            ) : null
                        }
                    </>
                    : null
            }
        </Container>
    )
}

export default ConsignmentVoyageSummary;